<template>
  <a-spin :spinning="fullLoading">
    <div class="loginwrapper">
      <div class="login_inner">
        <div class="login_left"></div>
        <div class="login_right">
          <h1 class="first_title" v-html="$config.appName"></h1>
          <div class="formItem">
            <a-input
              size="large"
              v-model.trim="name"
              placeholder="请输入账号"
              @keyup.enter="toLogin"
            >
              <a-icon slot="prefix" class="input_icon" type="user" />
            </a-input>
          </div>
          <div class="formItem">
            <a-input-password
              size="large"
              v-model.trim="pwd"
              placeholder="请输入密码"
              @keyup.enter="toLogin"
            >
              <a-icon slot="prefix" class="input_icon" type="lock" />
            </a-input-password>
          </div>
          <div class="formItem" style="width: 240px">
            <a-input
              size="large"
              v-model.trim="code"
              placeholder="请输入验证码"
              @keyup.enter="toLogin"
            >
              <a-icon slot="prefix" class="input_icon" type="safety" />
            </a-input>
            <img
              class="code"
              :src="imageUrl"
              alt="点击刷新验证码"
              @click="getCode"
            />
          </div>
          <div class="formItem">
            <a-alert
              :message="errorMsg"
              class="login_tip"
              v-if="!isPass"
              type="error"
              banner
            />
          </div>
          <div class="formItem">
            <a-button
              size="large"
              type="primary"
              class="loginBtn"
              @click="toLogin"
              block
              >登 录</a-button
            >
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
let _this;
export default {
  name: "Login",
  data() {
    return {
      name: "",
      pwd: "",
      code: "",
      imageUrl: "",
      isloading: false,
      isPass: true,
      errorMsg: "",
      uuid: "",
      fullLoading: false,
    };
  },
  created() {
    this.clearAuth();
  },
  mounted() {
    _this = this;
    _this.getCode();
  },
  watch: {
    name: () => {
      if (_this.name == "") {
        _this.isPass = true;
      }
    },
    pwd: () => {
      if (_this.pwd == "") {
        _this.isPass = true;
      }
    },
  },
  methods: {
    ...mapActions({
      stateLogin: "currentUser/login",
      generateRoutes: "permission/generateRoutes",
      setLeftMenus: "systemMenu/setLeftMenus",
      clearAuth: "currentUser/clearAuth",
    }),
    ...mapGetters({
      getAuthoritys: "currentUser/getAuthoritys",
    }),
    async getCode() {
      _this.uuid = _this.$util.uuid();
      _this.imageUrl = `${_this.$config.baseUrl}/login/checkcode?appKey=uas&uuid=${
        _this.uuid
      }&r=${Math.random()}`;
    },
    toLogin() {
      if (_this.name == "") {
        _this.isPass = false;
        _this.errorMsg = "请输入账号";
        return;
      }
      if (_this.pwd == "") {
        _this.isPass = false;
        _this.errorMsg = "请输入密码";
        return;
      }
      if (_this.code == "") {
        _this.isPass = false;
        _this.errorMsg = "请输入验证码";
        return;
      }
      let data = {
        name: _this.name,
        password: _this.pwd,
        code: _this.code,
        uuid: _this.uuid,
      };
      _this.login(data);
    },
    async login(loginData) {
      _this.isloading = true;
      let redirect = _this.$route.query.redirect;
      let result = await _this.stateLogin(loginData);
      if (result.code == _this.$commons.RespCode.success) {
        let accessedRoutes = await _this.generateRoutes(null);
        if (accessedRoutes.length == 0) {
          _this.$message.error("您没有操作权限");
        } else {
          _this.setLeftMenus(accessedRoutes);
          _this.$message.success("登录成功");
          redirect = decodeURIComponent(redirect || "/");
          _this.$router.push({ path: redirect });
        }
      } else {
        _this.isPass = false;
        _this.errorMsg = result.message;
        _this.isloading = false;
      }
    },
  },
};
</script>
<style lang="less">
.loginwrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(~@/assets/img/login_bg.png) no-repeat center center;
  background-size: 100% 100%;
  .login_inner {
    width: 1265px;
    height: 594px;
    background-color: #ffffff;
    box-shadow: 0px 6px 16px 0px rgba(101, 160, 255, 0.36);
    position: absolute;
    top: 50%;
    margin-top: -297px;
    left: 50%;
    border-radius: 20px;
    margin-left: -633px;
    .login_left {
      width: 716px;
      height: 594px;
      background: url(~@/assets/img/loginBg.jpg) no-repeat center center;
      background-size: 100% 100%;
      position: relative;
      float: left;
      img {
        position: absolute;
        bottom: 25px;
        left: 28px;
      }
    }
    .login_right {
      float: right;
      width: 549px;
      h1 {
        font-size: 30px;
        color: #2553a8;
        // margin: 60px 0;
        text-align: center;
      }
      .first_title {
        margin-top: 100px;
        margin-bottom: 60px;
      }
      .formItem {
        width: 342px;
        height: 44px;
        margin-left: 103px;
        margin-bottom: 12px;
        .code {
          height: 40px;
          width: 85px;
          cursor: pointer;
          float: left;
          margin-left: 256px;
          margin-top: -40px;
        }
        .login_tip {
          width: 345px;
          margin: 0 auto;
        }
        .ant-input-prefix {
          left: 6px;
        }
        .input_icon {
          font-size: 18px;
          color: #848484;
        }
      }
    }
  }
}
</style>
